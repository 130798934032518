<template>
    <div>
        <div class="container-lg mt-3 mt-md-5 mb-3 mb-md-5">

            <Alerts :_success="success" :_error="error"></Alerts>

            <form v-if="tab == 'create'" @submit.prevent="createSurvey()">
                <div class="preview-title">
                    <h1 v-if="survey.id">Edit Survey Details</h1>
                    <h1 v-else>Create Survey</h1>
                </div>
                <div class="preview">
                    <div class="row mb-3 mb-sm-3">
                        <div class="col-lg-4">
                            <label class="form-label">Language</label>
                            <select class="form-select" v-model="lang" @change="updateSurveyLang()">
                                <option v-for="l in languages" :value="l.code" :key="l.code">{{ l.name }}</option>
                            </select>
                        </div>
                        <div class="col-lg-8">
                            <label class="form-label">&nbsp;</label>
                            <input v-if="!langSet" class="form-control text-danger form-control-error"
                                   :value="lang  + ` is not set please go ahead and add`">
                            <input v-else class="form-control text-success form-control-success"
                                   :value="lang  + ` language is set for this survey`">
                        </div>
                    </div>
                    <div class="row mb-0 mb-sm-3">
                        <div class="col-lg-6 mb-3 mb-sm-0">
                            <label class="form-label">Survey Title</label>
                            <b-form-input
                                type="text"
                                v-model="survey.title"
                                :disabled="wait"
                                placeholder="Give your survey a title..."
                                required
                            >
                            </b-form-input>
                        </div>
                        <div class="col-lg-6 mb-3 mb-sm-0">
                            <label class="form-label">Survey Categories</label>
                            <multiselect
                                class=""
                                v-model="selected_categories"
                                :options="categories"
                                :disabled="wait"
                                :multiple="true"
                                track-by="id"
                                :preserve-search="true"
                                :hide-selected="true"
                                placeholder="Select categories" label="name" @input="setCategoryIds">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 mb-3 mb-sm-0">
                            <label class="form-label">Start Date</label>
                            <Datepicker id="start_date" :bootstrap-styling="true" :disabledDates="disabledDates"
                                        v-model="survey.start_date" maximum-view="day" :disabled="wait"
                                        placeholder="Start date" :required="true"></Datepicker>
                        </div>
                        <div class="col-md-6">
                            <label class="form-label">End Date</label>
                            <Datepicker id="end_date" :bootstrap-styling="true" :disabledDates="disabledDates"
                                        v-model="survey.end_date" maximum-view="day" :disabled="wait"
                                        placeholder="End date" :required="true"></Datepicker>
                        </div>
                    </div>

                    <div class="row mb-0 mb-sm-3">
                        <div class="col-md-6 mb-3 mb-sm-0">
                            <label class="form-label">Tags</label>
                            <input type="text" class="form-control" v-model="survey.tags" placeholder="Eg #tag #tag1"/>
                        </div>
                        <div class="col-md-6 mb-3 mb-sm-0">
                            <label class="form-label">Type</label>
                            <select v-model="survey.type" required class="form-select">
                                <option value="survey">Survey</option>
                                <option value="discussion" disabled>Discussion (Coming soon)</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <label class="form-label">Survey Description</label>
                            <textarea
                                class="form-control"
                                rows="4"
                                placeholder="Write a brief description about your survey..."
                                v-model="survey.description"
                                required
                            ></textarea>
                            <div class="form-text">A survey description is important to help users understand what your
                                survey is about.
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-info">
                        <font-awesome-icon icon="map-marker-alt"/>
                        Respondents' geolocation is enabled by default. This can be disabled in your survey settings.
                    </div>

                    <div class="row mb-3">
                        <div class="col">
                            <div class="d-inline-block me-1 mb-2 mb-md-0">
                                <button type="submit" class="custom-btn custom-btn-success" :disabled="wait">
                                    <template v-if="survey.id">Save Changes</template>
                                    <template v-else>Create Survey</template>
                                </button>
                            </div>
                            <div v-if="survey.id" class="d-inline-block me-1 mb-2 mb-md-0">
                                <button @click.prevent="deleteSurvey(survey, 'no')"
                                        class="custom-btn custom-btn-danger">
                                    Delete Survey
                                </button>
                            </div>
                            <div class="d-inline-block mb-2 mb-md-0">
                                <router-link to="/surveys">
                                    <button class="custom-btn custom-btn-secondary">Cancel</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <Settings v-if="tab == 'settings'" :survey="survey"></Settings>

        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import Alerts from "../Helpers/Alerts"
import Settings from "../Posts/Settings"
import Multiselect from "vue-multiselect";


export default {
    name: "CreateSurvey",
    props: ['id'],
    components: {Alerts, Datepicker, Settings, Multiselect},
    data() {
        return {
            survey: {
                id: '',
                title: '',
                category_ids: [],
                tags: '#sabasi',
                type: 'survey',
                start_date: new Date(),
                end_date: new Date(),
                description: '',
                lang: 'en',
                geo: ''
            },
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 2)),
                from: new Date(new Date().setDate(new Date().getDate() + 30))
            },
            categories: [],
            selected_categories: [],

            lang: 'en',
            languages: [],
            langSet: true,
            tab: 'create'
        }
    },
    mounted() {
        this.getCategories()
        this.loadLanguages()
        this.getUserLocation()

        if (this.id) {
            this.$http.get('/api/profile/survey/' + this.id).then(response => {
                this.survey = response.data.data
                this.selected_categories = this.survey.categories
                this.updateSurveyLang()
            })
        }
    },
    methods: {
        loadLanguages() {
            this.$http.get('/api/languages').then(response => {
                this.languages = response.data.data
            })
        },
        close() {
            this.$emit('close');
            this.$parent.isModalVisible = false;
        },
        setCategoryIds(categories) {
            let ids = [];
            categories.forEach((i) => {
                ids.push(i.id);
            });
            this.survey.category_ids = ids;
        },
        updateSurveyLang() {
            let lang = this.survey.languages.find(a => a.lang === this.lang)
            if (lang !== undefined) {
                this.survey.title = lang.title
                this.survey.tags = lang.tags
                this.survey.description = lang.description
                this.langSet = true
            } else {
                this.survey.title = ''
                this.survey.tags = ''
                this.survey.description = ''
                this.langSet = false
            }
        },
        createSurvey() {
            this.clear()
            this.setLoading()

            this.survey.lang = this.lang
            this.$http.post('/api/profile/survey/create', this.survey).then(response => {
                this.survey = response.data.data
                this.$toaster.success('Survey details updated')
                this.$parent.isModalVisible = false;
                this.$store.dispatch('loadNotificationsCount')
                setTimeout(() => (this.$router.push(`/profile/surveys/view/${this.survey.code}/questionnaire`)), 500)
            }).catch(error => {
                this.showError(error)
                this.$toaster.error(this.error)
            }).then(() => {
                this.wait = false
            })
        },
        getCategories() {
            this.$http.get('/api/categories').then(res => {
                this.categories = res.data.data
            })
        },
        clearSurvey() {
            this.survey = {
                id: '',
                name: '',
                start_date: '',
                end_date: '',
                description: ''
            }
        },
      deleteSurvey(survey, permanent) {
        if (confirm('Are you sure you want to delete this survey ' + (permanent === 'yes' ? 'permanently?' : '?'))) {
          this.$http.post(`/api/profile/survey/${survey.code}/delete?permanent=` + permanent).then(() => {
            this.$toaster.warning('Survey Has been Archived');
            window.location.href = '/profile/surveys/archived';
          }).catch(error => {
            this.showError(error)
          })
        }
      },
        getUserLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showPosition, this.showPositionError)
            }
        },
        showPosition(position) {
            let lat = position.coords.latitude
            let lon = position.coords.longitude
            this.survey.geo = lat + ',' + lon
            // let map = "https://maps.googleapis.com/maps/api/staticmap?center=" + lat + lon + "&zoom=14&size=400x300&sensor=false&key=" + process.env.VUE_APP_MAP_KEY
            // console.log(map)
        },
        showPositionError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    this.error = "User denied the request for Geolocation."
                    break;
                case error.POSITION_UNAVAILABLE:
                    this.error = "Location information is unavailable."
                    break;
                case error.TIMEOUT:
                    this.error = "The request to get user location timed out."
                    break;
                case error.UNKNOWN_ERROR:
                    this.error = "An unknown error occurred."
                    break;
            }
        },
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>